<header class="sticky" [ngClass]="class" [class.fixed]="stick && sticky">
  <div class="mobile-fix-option"></div>
  <div class="top-header" *ngIf="topbar">
    <div class="container">
      <div class="row">
        <div class="col-lg-6">
          <div class="header-contact">
            <ul>
              <li>{{ translate.instant('header.welcome') }}</li>
              <li class="tel"><i class="fa fa-phone" aria-hidden="true"></i>EU: ++00 000 000 000</li>
              <li class="tel"><i class="fa fa-phone" aria-hidden="true"></i>UK: +44 00 (0)000 000 0000</li>
            </ul>
          </div>
        </div>
        <div class="col-lg-6 text-right">
          <ul class="header-dropdown">
            <li class="lang"><a href="/" class="languageButton" (click)="languageSwitcher($event, 'en')">EN&nbsp;&nbsp;|&nbsp;&nbsp;<!--<img src="assets/images/flags-large/United-Kingdom.png" />--></a></li>
            <li class="lang right"><a href="/" class="languageButton" (click)="languageSwitcher($event, 'es')">ES<!--<img src="assets/images/flags-large/Spain.png" />--></a></li>
            <!--
            <li class="compare">
              <a [routerLink]="['/shop/compare']"><i class="fa fa-random" aria-hidden="true"></i>Language</a>
            </li>
            <li class="onhover-dropdown mobile-account">
              <i class="fa fa-user" aria-hidden="true"></i> My Account
              <ul class="onhover-show-div">
                <li>
                  <a data-lng="en">
                    Login
                  </a>
                </li>
                <li>
                  <a data-lng="es">
                    Logout
                  </a>
                </li>
              </ul>
            </li>
          -->
          </ul>
        </div>
      </div>
    </div>
  </div>
  <div class="container">
    <div class="row">
      <div class="col-sm-12">
        <div class="main-menu">
          <div class="menu-left">
            <div class="brand-logo">
              <a routerLink="/" id="headerlogo">
                <img [src]="themeLogo" class="img-fluid" alt="logo">
              </a>
            </div>
          </div>
          <div class="menu-right pull-right">
            <app-menu></app-menu>
            <!--
            <div>
              <app-settings></app-settings>
            </div>
            -->
          </div>
        </div>
      </div>
    </div>
  </div>
</header>
