import { Injectable, HostListener, ChangeDetectorRef } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject } from 'rxjs';

// Menu
export interface Menu {
	path?: string;
	icon?: string;
	title?: string | SafeHtml;
	type?: string;
	megaMenu?: boolean;
	image?: string;
	active?: boolean;
	badge?: boolean;
	badgeText?: string;
	children?: Menu[];
}

@Injectable({
	providedIn: 'root'
})

export class NavService {

	MENUITEMS: Menu[] = [];
	LEFTMENUITEMS: Menu[] = [];

	// Array
	items = new BehaviorSubject<Menu[]>(this.MENUITEMS);
	leftMenuItems = new BehaviorSubject<Menu[]>(this.LEFTMENUITEMS);

	constructor(private _sanitizer:DomSanitizer, private translate: TranslateService) {
		this.translate.onLangChange.subscribe((translated: any) => {
			this.initMenuValues();
		});

		this.initMenuValues();
	 }

	public screenWidth: any;
	public leftMenuToggle: boolean = false;
	public mainMenuToggle: boolean = false;

	// Windows width
	@HostListener('window:resize', ['$event'])
	onResize(event?) {
		this.screenWidth = window.innerWidth;
	}

	initMenuValues() {
		this.MENUITEMS = [
			{
				title: 'TechCity', type: 'sub', active: false, children: [
					{ path: '/pages/aboutus', title: this.translate.instant('pageLinkNames.about'), type: 'link' },
					{ path: '/pages/collection', title: this.translate.instant('pageLinkNames.passions'), type: 'link' },
				]
			},
			{
				title: this.translate.instant('pageLinkNames.services'), type: 'sub', megaMenu: true, badge: true, active: false, badgeText: this.translate.instant('pageLinkNames.our') /*'E-commerce'*/, children: [
					{
						title: this.translate.instant('pageLinkNames.industries'), type: 'sub', active: false, children: [
							{ path: '/industries/banking-and-finance', icon: 'fa-globe', title: this.translate.instant('pageLinkNames.bankingFinance'), type: 'link' },
							{ path: '/industries/banking-and-finance', icon: 'fa-globe', title: this.translate.instant('pageLinkNames.pharmaceuticals'), type: 'link' },
							{ path: '/industries/banking-and-finance', icon: 'fa-globe', title: this.translate.instant('pageLinkNames.goldPreciousMetals'), type: 'link' },
							{ path: '/industries/ecommerce', icon: 'fa-globe', title: this.translate.instant('pageLinkNames.energySector'), type: 'link' },
							{ path: '/industries/ecommerce', icon: 'fa-globe', title: this.translate.instant('pageLinkNames.sportsInstitutions'), type: 'link' },
						]
					},
					{
						// this._sanitizer.bypassSecurityTrustHtml('<i class="fa-thin fa-arrow-right"></i>')
						title: '|', type: 'sub',  active: false, children: [
							{ path: '/industries/ecommerce', icon: 'fa-globe', title: this.translate.instant('pageLinkNames.ecommerce'), type: 'link' },
							{ path: '/industries/travel', icon: 'fa-globe', title: this.translate.instant('pageLinkNames.travelFlights'), type: 'link' },
							{ path: '/industries/travel', icon: 'fa-globe', title: this.translate.instant('pageLinkNames.cloudDataCenters'), type: 'link' },
							{ path: '/industries/travel', icon: 'fa-globe', title: this.translate.instant('pageLinkNames.cybersecurity'), type: 'link' },
							// { path: '/pages/blog/right/sidebar', icon: 'fa-globe', title: 'Advertising & Media', type: 'link' },
							{ path: '/industries/b-startups', icon: 'fa-globe', title: this.translate.instant('pageLinkNames.telecommunications'), type: 'link' },
						]
					},
					{
						title: '|', type: 'sub',  active: false, children: [
							{ path: '/industries/b-startups', icon: 'fa-globe', title: this.translate.instant('pageLinkNames.businessStartups'), type: 'link' },
							{ path: '/industries/b-startups', icon: 'fa-globe', title: this.translate.instant('pageLinkNames.educationalInstitutions'), type: 'link' },
							{ path: '/industries/government-and-institutions', icon: 'fa-globe', title: this.translate.instant('pageLinkNames.ukGovernment'), type: 'link' },
							{ path: '/industries/government-and-institutions', icon: 'fa-globe', title: this.translate.instant('pageLinkNames.spanishGovernment'), type: 'link' },
							{ path: '/industries/government-and-institutions', icon: 'fa-globe', title: this.translate.instant('pageLinkNames.euInstitutions'), type: 'link' },
						]
					},
					{
						title: this.translate.instant('pageLinkNames.platformServices'), type: 'sub', active: false, children: [
							{ path: '/services/consulting-partner', icon: 'fa-bolt', title: this.translate.instant('pageLinkNames.developerPartner'), type: 'link' },
							{ path: '/services/platform-migrations', icon: 'fa-bolt', title: this.translate.instant('pageLinkNames.platformMigrations'), type: 'link' },
							{ path: '/services/platform-migrations', icon: 'fa-bolt', title: this.translate.instant('pageLinkNames.ecommerceIntegrations'), type: 'link' },
							{ path: '/services/fullstack-development', icon: 'fa-bolt', title: this.translate.instant('pageLinkNames.platformMaintenance'), type: 'link' },
							{ path: '/services/fullstack-development', icon: 'fa-bolt', title: this.translate.instant('pageLinkNames.apiIntegrations'), type: 'link' }
						]
					},
					{
						title: '|', type: 'sub', active: false, children: [
							{ path: '/services/fullstack-development', icon: 'fa-bolt', title: this.translate.instant('pageLinkNames.frontendFullstackDevs'), type: 'link' },
							{ path: '/services/backend-development', icon: 'fa-bolt', title: this.translate.instant('pageLinkNames.backendDevServices'), type: 'link' },
							{ path: '/services/backend-development', icon: 'fa-bolt', title: this.translate.instant('pageLinkNames.amazonWebServices'), type: 'link' },
							{ path: '/services/backend-development', icon: 'fa-bolt', title: this.translate.instant('pageLinkNames.azureDevOps'), type: 'link' },
							{ path: '/services/payment-and-booking-solutions', icon: 'fa-bolt', title: this.translate.instant('pageLinkNames.microservices'), type: 'link' },
						]
					},
					{
						title: '|', type: 'sub', active: false, children: [
							{ path: '/services/platform-migrations', icon: 'fa-bolt', title: this.translate.instant('pageLinkNames.applicationArchitecture'), type: 'link' },
							{ path: '/services/payment-and-booking-solutions', icon: 'fa-bolt', title: this.translate.instant('pageLinkNames.assetManagement'), type: 'link' },
							{ path: '/services/payment-and-booking-solutions', icon: 'fa-bolt', title: this.translate.instant('pageLinkNames.paymentBookings'), type: 'link' },
							{ path: '/services/payment-and-booking-solutions', icon: 'fa-bolt', title: this.translate.instant('pageLinkNames.uiuxWorkflows'), type: 'link' },
						]
					},
				]
			},/*
			{
				title: 'Features', type: 'sub', active: false, children: [
					{
						title: 'sidebar', type: 'sub', active: false, children: [
							{ path: '/shop/product/left/sidebar/trim-dress', icon: 'fa-globe', title: 'left-sidebar', type: 'link' },
							{ path: '/shop/product/right/sidebar/trim-dress', icon: 'fa-globe', title: 'right-sidebar', type: 'link' },
							{ path: '/shop/product/no/sidebar/trim-dress', icon: 'fa-globe', title: 'no-sidebar', type: 'link' }
						]
					},
					{ path: '/shop/product/three/column/trim-dress', icon: 'fa-globe', title: 'three-column', type: 'link' },
					{ path: '/shop/product/four/image/belted-dress', icon: 'fa-globe', title: 'four-image', type: 'link' },
					{ path: '/shop/product/bundle/trim-dress', icon: 'fa-globe', title: 'bundle-product', type: 'link' },
					{ path: '/shop/product/image/outside/trim-dress', icon: 'fa-globe', title: 'image-outside', type: 'link' }
				]
			},*//*
			{
				title: 'pages', type: 'sub', active: false, children: [
					{
						title: 'account', type: 'sub', active: false, children: [
							{ path: '/pages/wishlist', title: 'wishlist', type: 'link' },
							{ path: '/pages/cart', title: 'cart', type: 'link' },
							{ path: '/pages/dashboard', title: 'dashboard', type: 'link' },
							{ path: '/pages/login', title: 'login', type: 'link' },
							{ path: '/pages/register', title: 'register', type: 'link' },
							{ path: '/pages/contact', title: 'contact', type: 'link' },
							{ path: '/pages/forget/password', title: 'forget-password', type: 'link' },
							{ path: '/pages/profile', title: 'profile', type: 'link' },
							{ path: '/pages/checkout', title: 'checkout', type: 'link' },
						]
					},
					{ path: '/pages/aboutus', title: 'about-us', type: 'link' },
					{ path: '/pages/search', title: 'search', type: 'link' },
					{ path: '/pages/typography', title: 'typography', type: 'link', badge: true, badgeText: 'new' },
					{ path: '/pages/review', title: 'review', type: 'link', badge: true, badgeText: 'new' },
					{ path: '/pages/order/success', title: 'order-success', type: 'link' },
						{ 
							title: 'compare', type: 'sub', active: false, children: [
								{ path: '/pages/compare/one', title: 'compare-1', type: 'link' },
								{ path: '/pages/compare/two', title: 'compare-2', type: 'link', badge: true, badgeText: 'new' }
							]
						},
					{ path: '/pages/collection', title: 'collection', type: 'link' },
					{ path: '/pages/lookbook', title: 'lookbook', type: 'link' },
					{ path: '/pages/404', title: '404', type: 'link' },
					{ path: '/pages/comingsoon', title: 'coming-soon', type: 'link', badge: true, badgeText: 'new' },
					{ path: '/pages/faq', title: 'faq', type: 'link' }
				]
			},*/
			{
				title: this.translate.instant('pageLinkNames.contacts'), type: 'link', active: false, children: [
					{ path: '/pages/contact', title: this.translate.instant('pageLinkNames.sales'), type: 'link' },
					{ path: '/pages/send-cv', title: this.translate.instant('pageLinkNames.sendCV'), type: 'link' },
				]
			}
		];		
	
		this.LEFTMENUITEMS = [
			{
				title: 'clothing', type: 'sub', megaMenu: true, active: false, children: [
				  {
					  title: 'mens fashion',  type: 'link', active: false, children: [
						  { path: '/', title: 'sports wear',  type: 'link' },
						  { path: '/', title: 'top',  type: 'link' },
						  { path: '/', title: 'bottom',  type: 'link' },
						  { path: '/', title: 'ethic wear',  type: 'link' },
						  { path: '/', title: 'sports wear',  type: 'link' },
						  { path: '/', title: 'shirts',  type: 'link' },
						  { path: '/', title: 'bottom',  type: 'link' },
						  { path: '/', title: 'ethic wear',  type: 'link' },
						  { path: '/', title: 'sports wear',  type: 'link' }
					  ]
				  },
				  {
					  title: 'women fashion',  type: 'link', active: false, children: [
						  { path: '/', title: 'dresses',  type: 'link' },
						  { path: '/', title: 'skirts',  type: 'link' },
						  { path: '/', title: 'westarn wear',  type: 'link' },
						  { path: '/', title: 'ethic wear',  type: 'link' },
						  { path: '/', title: 'bottom',  type: 'link' },
						  { path: '/', title: 'ethic wear',  type: 'link' },
						  { path: '/', title: 'sports wear',  type: 'link' },
						  { path: '/', title: 'sports wear',  type: 'link' },
						  { path: '/', title: 'bottom wear',  type: 'link' }
					  ]
				  },
				]
			},
			{
				title: 'bags', type: 'sub', active: false, children: [
				  { path: '/', title: 'shopper bags', type: 'link' },
				  { path: '/', title: 'laptop bags', type: 'link' },
				  { path: '/', title: 'clutches', type: 'link' },
				  {
					  path: '/', title: 'purses', type: 'link', active: false, children: [
						  { path: '/', title: 'purses',  type: 'link' },
						  { path: '/', title: 'wallets',  type: 'link' },
						  { path: '/', title: 'leathers',  type: 'link' },
						  { path: '/', title: 'satchels',  type: 'link' }
					  ]
				  },
				]
			},
			{
				title: 'footwear', type: 'sub', active: false, children: [
				  { path: '/', title: 'sport shoes', type: 'link' },
				  { path: '/', title: 'formal shoes', type: 'link' },
				  { path: '/', title: 'casual shoes', type: 'link' }
				]
			},
			{
				path: '/', title: 'watches', type: 'link'
			},
			{
				title: 'Accessories', type: 'sub', active: false, children: [
				  { path: '/', title: 'fashion jewellery', type: 'link' },
				  { path: '/', title: 'caps and hats', type: 'link' },
				  { path: '/', title: 'precious jewellery', type: 'link' },
				  {
					  path: '/', title: 'more..', type: 'link', active: false, children: [
						  { path: '/', title: 'necklaces',  type: 'link' },
						  { path: '/', title: 'earrings',  type: 'link' },
						  { path: '/', title: 'rings & wrist wear',  type: 'link' },
						  {
							  path: '/', title: 'more...',  type: 'link', active: false, children: [
								  { path: '/', title: 'ties',  type: 'link' },
								  { path: '/', title: 'cufflinks',  type: 'link' },
								  { path: '/', title: 'pockets squares',  type: 'link' },
								  { path: '/', title: 'helmets',  type: 'link' },
								  { path: '/', title: 'scarves',  type: 'link' },
								  {
									  path: '/', title: 'more...',  type: 'link', active: false, children: [
										  { path: '/', title: 'accessory gift sets',  type: 'link' },
										  { path: '/', title: 'travel accessories',  type: 'link' },
										  { path: '/', title: 'phone cases',  type: 'link' }
									  ]
								  },
							]
						  }
					  ]
				  },
				]
			},
			{
				path: '/', title: 'house of design', type: 'link'
			},
			{
				title: 'beauty & personal care', type: 'sub', active: false, children: [
				  { path: '/', title: 'makeup', type: 'link' },
				  { path: '/', title: 'skincare', type: 'link' },
				  { path: '/', title: 'premium beaty', type: 'link' },
				  {
					  path: '/', title: 'more..', type: 'link', active: false, children: [
						  { path: '/', title: 'fragrances',  type: 'link' },
						  { path: '/', title: 'luxury beauty',  type: 'link' },
						  { path: '/', title: 'hair care',  type: 'link' },
						  { path: '/', title: 'tools & brushes',  type: 'link' }
					  ]
				  },
				]
			},
			{
				path: '/', title: 'home & decor', type: 'link'
			},
			{
				path: '/', title: 'kitchen', type: 'link'
			}
		];

		this.items.next(this.MENUITEMS);
		this.leftMenuItems.next(this.LEFTMENUITEMS);
	}
}
