<!--footer section -->
<footer [class]="class">
	<div class="light-layout" *ngIf="newsletter">
		<div class="container">
			<section class="small-section border-section border-top-0">
				<div class="row">
					<div class="col-lg-6">
						<div class="subscribe">
							<div>
								<h4>{{ "newsletterComponent.title" | translate }}</h4>
								<p>{{ "newsletterComponent.body" | translate }} </p>
							</div>
						</div>
					</div>
					<div class="col-lg-6">
						<form class="form-inline subscribe-form" action="https://pixelstrap.us19.list-manage.com/subscribe/post?u=5a128856334b598b395f1fc9b&amp;id=082f74cbda" target="_blank" method="post">
							<div class="form-group mx-sm-3">
								<input type="email" #name name="EMAIL" id="mce-EMAIL" class="form-control" placeholder="{{ 'newsletterComponent.form.placeholder' | translate }}">
							</div>
							<button type="submit" class="btn btn-solid">{{ 'newsletterComponent.form.subscribeButton' | translate }}</button>
						</form>
					</div>
				</div>
			</section>
		</div>
	</div>
	<section class="section-b-space light-layout">
		<div class="container">
			<div class="row footer-theme partition-f">
				<div class="col-lg-4 col-md-6">
					<div class="footer-title footer-mobile-title">
						<h4>about</h4>
					</div>
					<div class="footer-contant">
						<div class="footer-logo">
							<img [src]="themeLogo" alt="logo">
						</div>
						<p>{{ "footer.body" | translate }}</p>
						<div class="footer-social">
							<ul>
                                <li>
                                    <a href="https://www.linkedin.com/company/techcitysystems"><i class="fa fa-linkedin" aria-hidden="true"></i></a>
                                </li>
                                <li>
                                    <a href="https://www.youtube.com/@techcitysystems"><i class="fa fa-youtube" aria-hidden="true"></i></a>
                                </li>
                                <li>
                                    <a href="https://www.facebook.com/people/TechCity-Systems/100090846219833/"><i class="fa fa-facebook" aria-hidden="true"></i></a>                 
                                </li>
                                <li>
                                    <a href="https://twitter.com/techcitysystems"><i class="fa fa-twitter" aria-hidden="true"></i></a>
                                </li>
                                <li>
                                    <a href="https://www.instagram.com/techcitysystems/"><i class="fa fa-instagram" aria-hidden="true"></i></a>
                                </li>
                            </ul>
                        </div>
					</div>
				</div>
				<div class="col offset-xl-1">
					<div class="sub-title">
						<div class="footer-title">
                            <h4>{{ translate.instant('footer.softwareHeader') | translate }} <span style="color: #f0b54d;">&amp;</span> {{ translate.instant('footer.servicesHeader') | translate }}</h4>
                        </div>
                        <div class="footer-contant">
                            <ul>
                                <li><a href="/services/platform-migrations">{{ translate.instant('footer.softwareAndservices')[0] | translate }}</a></li>
                                <li><a href="/services/payment-and-booking-solutions">{{ translate.instant('footer.softwareAndservices')[1] | translate }}</a></li>
                                <li><a href="/services/payment-and-booking-solutions">{{ translate.instant('footer.softwareAndservices')[2] | translate }}</a></li>
                                <li><a href="/services/payment-and-booking-solutions">{{ translate.instant('footer.softwareAndservices')[3] | translate }}</a></li>
								<li><a href="/services/payment-and-booking-solutions">{{ translate.instant('footer.softwareAndservices')[4] | translate }}</a></li>
                            </ul>
							
                        </div>
					</div>
				</div>
				<div class="col">
					<div class="sub-title">
                        <div class="footer-contant" style="margin-top: 43px">
                            <ul><!-- below is dummy link and text, need to make relevent to software and platform development-->
                                <li><a href="/industries/banking-and-finance">{{ translate.instant('footer.softwareAndservices')[5] | translate }}</a></li>
                                <li><a href="/industries/banking-and-finance">{{ translate.instant('footer.softwareAndservices')[6] | translate }}</a></li>
                                <li><a href="/industries/banking-and-finance">{{ translate.instant('footer.softwareAndservices')[7] | translate }}</a></li>
                                <li><a href="/industries/banking-and-finance">{{ translate.instant('footer.softwareAndservices')[8] | translate }}</a></li>
                                <li><a href="/services/fullstack-development">{{ translate.instant('footer.softwareAndservices')[9] | translate }}</a></li>
                            </ul>
                        </div>
					</div>
				</div>
				<div class="col">
					<div class="sub-title">
						<div class="footer-title">
						    <h4>{{ translate.instant('footer.contactsHeader') | translate }}</h4>
						</div>
						<div class="footer-contant">
						    <ul class="contact-list">
						        <li><i class="fa fa-globe"></i><a href="/pages/contact">{{ translate.instant('footer.contacts.contactUsHere') | translate }}</a>
									<!--<br /><a href="/pages/send-cv">Click Here to Send CV</a></li>-->
								<li class="tel"><i class="fa fa-phone" aria-hidden="true"></i>EU: +00 000 000 000<!--865 481 012--> <br /> UK: +44 00 (0)000 000 0000</li>
								<li span="divider"><hr /></li>
								<li span="send-cv"><i class="fa fa-file"></i><a href="/pages/send-cv">{{ translate.instant('footer.contacts.SendUsYourCV') | translate }}</a></li>
						        <!--<li><i class="fa fa-envelope-o"></i><a>sales@techcitysystem.com</a></li>-->
						    </ul>
						</div>
					</div>
				</div>
			</div>
		</div>
	</section>
	<div class="sub-footer">
		<div class="container">
			<div class="row">
                <div class="col-xl-6 col-md-6 col-sm-12">
                  <div class="footer-end">
                    <p><i class="fa fa-copyright" aria-hidden="true"></i> {{ today | date:'y'}} Techcity System - All Rights Reserved</p>
                  </div>
                </div>
                <div class="col-xl-6 col-md-6 col-sm-12">
                  <div class="payment-card-bottom">
                    <ul>
                      <li>
                        <a><img src="assets/images/icon/visa.png" alt=""></a>
                      </li>
                      <li>
                        <a><img src="assets/images/icon/mastercard.png" alt=""></a>
                      </li>
                      <li>
                        <a><img src="assets/images/icon/paypal.png" alt=""></a>
                      </li>
                      <li>
                        <a><img src="assets/images/icon/american-express.png" alt=""></a>
                      </li>
                      <li>
                        <a><img src="assets/images/icon/discover.png" alt=""></a>
                      </li>
                    </ul>
                  </div>
                </div>
            </div>
		</div>
	</div>
</footer>
<!--footer section end -->